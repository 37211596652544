import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import React, { useCallback, useMemo, useRef } from 'react';
import {
  LIST_TYPES,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { DELETE_AD } from './graphql/Mutations';
import { GET_ADS } from './graphql/Queries';

// COMMENTING FOR FUTURE USE
// const AdCard = ({
//   createdAt,
//   id,
//   title,
//   description,
//   image,
//   onEdit,
//   onPreview,
//   isViewOnly,
//   isAssetAllowed,
//   isLabelAllowed,
//   isDeleteAllowed,
//   handleDelete
// }) => {
//   return (
//     <CustomCard
//       onClick={onPreview}
//       alt={title}
//       src={getImageUrl(image?.url) ?? imagePlaceholder}
//       blurHash={image?.blurhash}
//       title={moment(createdAt).format('ll')}
//       heading={title}
//       description={description}
//       primaryOnClick={() => onEdit(id)}
//       secondaryOnClick={(e) => {
//         e?.stopPropagation();
//         handleDelete(id);
//       }}
//       primary={
//         isAssetAllowed &&
//         isLabelAllowed &&
//         (isViewOnly ? (
//           <>
//             <EyeFilled size={16} />
//             View
//           </>
//         ) : (
//           <>
//             <PencilSimpleLine size={16} />
//             Edit
//           </>
//         ))
//       }
//       secondary={
//         isDeleteAllowed && (
//           <>
//             <Trash size={16} />
//             Delete
//           </>
//         )
//       }
//     />
//   );
// };

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    search,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ adsAdmin }) => ({
  data: adsAdmin?.ads ?? [],
  count: adsAdmin?.count ?? 0
});

const getColumns = ({
  handleEdit: onEdit,
  isAssetAllowed,
  isLabelAllowed,
  isViewOnly,
  handleDelete: onDelete,
  isDeleteAllowed
}) => [
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    ellipsis: true,
    render: (createdAt) => {
      return <p>{moment(createdAt).format('ll')}</p>;
    }
  },
  {
    title: 'Title',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true
  },
  ...(isAssetAllowed && isLabelAllowed
    ? [
        {
          title: 'Action',
          dataIndex: 'id',
          render: (value) => (
            <div>
              <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Ad`}>
                <Button
                  type="text"
                  className="text-btn"
                  onClick={() => onEdit(value)}
                >
                  {isViewOnly ? <EyeFilled /> : <EditOutlined />}
                </Button>
              </Tooltip>
              {isDeleteAllowed && (
                <span onClick={(e) => e.stopPropagation()}>
                  <Tooltip title="Delete Ad">
                    <DeleteOutlined
                      onClick={() => {
                        onDelete(value);
                      }}
                    />
                  </Tooltip>
                </span>
              )}
            </div>
          )
        }
      ]
    : [])
];

const Ads = ({ history, location }) => {
  const filterProps = useFilterBar();
  const ref = useRef();
  const { pathname } = location;

  const adsBreadcrumbs = [
    { label: MODULES?.CONTENTS },
    { label: MODULES?.ADS }
  ];

  const [deleteAd] = useMutation(DELETE_AD, { onError() {} });

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const isLabelAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleShowModal = () => {
    history.push(`${ROUTES?.CONTENTS_ADS}/add`, {
      filters: filterProps?.filters,
      pathname
    });
  };

  const handleEdit = useCallback(
    (id) => {
      history.push(`${ROUTES?.CONTENTS_ADS}/${id}/edit`, {
        filters: filterProps?.filters,
        pathname
      });
    },
    [filterProps]
  );

  const handleDelete = async (id) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this Ad?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteAd({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (ref?.current?.removeItem && !errors) {
              ref.current.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  const columns = useMemo(
    () =>
      getColumns({
        handleEdit,
        isAddEditAllowed,
        isAssetAllowed,
        isLabelAllowed,
        isViewOnly,
        handleDelete,
        isDeleteAllowed
      }),
    [handleEdit]
  );

  return (
    <>
      <PageHeader
        menu={adsBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed && isAssetAllowed && isLabelAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search by ad name' }}
        show={{ listModes: false }}
      />
      <PageList
        ref={ref}
        filters={filterProps?.filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_ADS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
      />
    </>
  );
};

export default Ads;
