import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo, useRef } from 'react';
import {
  ADVERTISER_SORT_FIELD,
  LIST_TYPES,
  MODULES,
  ORDER,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { DELETE_ADVERTISER } from './graphql/Mutations';
import { GET_ADVERTISERS } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  isActive
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    isActive
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ advertisersAdmin }) => ({
  data: advertisersAdmin?.advertisers || [],
  count: advertisersAdmin?.count || 0
});

const Advertisers = ({ history, location }) => {
  const ref = useRef();
  const { pathname } = location;

  const advertisersBreadcrumbs = [
    { label: MODULES?.LABELS },
    { label: MODULES?.ADVERTISERS }
  ];

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: ADVERTISER_SORT_FIELD,
      order: ORDER[1].value,
      field: ADVERTISER_SORT_FIELD[0].value
    }
  });

  const filters = useMemo(
    () => ({
      ...filterProps.filters
    }),
    [filterProps.filters]
  );

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const [deleteAdvertiser] = useMutation(DELETE_ADVERTISER, {
    onError() {}
  });

  const handleEdit = (id) => {
    history?.push(`${ROUTES?.LABELS_ADVERTISERS}/${id}/edit`, {
      filters,
      pathname
    });
  };

  const handleDelete = async (id) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this advertiser?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteAdvertiser({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (ref.current.removeItem && !errors) {
              ref.current.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '22%'
    },
    {
      title: 'Status',
      dataIndex: 'disabledAt',
      ellipsis: true,
      render: (value) => (value ? 'InActive' : 'Active')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: true,
      width: '22%',
      render: (value) => (value ? <a href={`mailto:${value}`}>{value}</a> : '-')
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
      width: '22%',
      render: (contactName) => (
        <p>{!isEmpty(contactName) ? contactName : '-'}</p>
      )
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => (
        <div className="d-flex gap-8">
          <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Advertiser`}>
            {isViewOnly ? (
              <EyeFilled
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(record?.id);
                }}
              />
            ) : (
              <EditOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(record?.id);
                }}
              />
            )}
          </Tooltip>
          {isDeleteAllowed && (
            <span onClick={(e) => e.stopPropagation()}>
              <Tooltip title="Delete Advertiser">
                <DeleteOutlined
                  onClick={() => {
                    handleDelete(record?.id);
                  }}
                />
              </Tooltip>
            </span>
          )}
        </div>
      )
    }
  ];

  const handleShowModal = () => {
    history?.push(`${ROUTES?.LABELS_ADVERTISERS}/add`, {
      filters,
      pathname
    });
  };

  return (
    <>
      <PageHeader
        menu={advertisersBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Advertisers' }}
        show={{ listModes: false }}
      />
      <PageList
        ref={ref}
        filters={filters}
        listMode={LIST_TYPES?.TABLE}
        query={GET_ADVERTISERS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns: [...columns?.filter((item) => item !== false)] }}
      />
    </>
  );
};

export default Advertisers;
