import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, ColorPicker, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  DEFAULT_COLOR,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import SeoInput, {
  defaultSeoValues,
  getSeoFormFields
} from '../../../components/SeoInput';
import useCheckPermission from '../../../hooks/useCheckPermission';
import SelectAsset from '../../assets/components/SelectAsset';
import { SlugInput } from './components/FormInputs';
import { CREATE_TOPIC, UPDATE_TOPIC } from './graphql/Mutations';
import { GET_TOPIC } from './graphql/Queries';

const initialValues = {
  name: '',
  slug: '/',
  primaryColor: DEFAULT_COLOR,
  metaHeader: '',
  metaFooter: '',
  image: {
    url: '',
    id: ''
  },
  seo: defaultSeoValues
};

function CustomColorPicker({ onChange, ...rest }) {
  return (
    <ColorPicker
      onChange={(_, color) => {
        onChange(color);
      }}
      showText
      {...rest}
    />
  );
}

const AddEditTopic = ({ history, location, match: { params } }) => {
  const [form] = Form.useForm();
  const { filters, pathname } = location?.state || {};
  const { topicId } = params;

  const isEdit = !!topicId;

  const addEditTopicBreadcrumbs = [
    { label: MODULES?.LABELS },
    {
      label: MODULES?.TOPICS,
      route: ROUTES?.LABELS_TOPICS,
      addEditFilter: { filters }
    },
    topicId && { label: topicId },
    { label: params?.id ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [fetchTopicDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_TOPIC,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateTopic, { loading }] = useMutation(
    isEdit ? UPDATE_TOPIC : CREATE_TOPIC
  );

  useEffect(() => {
    if (isEdit && !!topicId) {
      fetchTopicDetails({
        variables: {
          id: topicId
        }
      })
        .then((res) => {
          const topic = res.data?.topicAdmin;
          if (topic) {
            const { name, primaryColor, slug, metaHeader, metaFooter } = topic;
            form.setFieldsValue({
              name,
              primaryColor: primaryColor ?? '',
              slug: slug.startsWith('/') ? slug : `/${slug}`,
              metaHeader: metaHeader ?? '',
              metaFooter: metaFooter ?? '',
              seo: getSeoFormFields(topic?.seo)
            });
          }
        })
        .catch();
    }
  }, [fetchTopicDetails, topicId, form, isEdit]);

  const handleCancel = () => {
    history.push(ROUTES?.LABELS_TOPICS, {
      onAddEditFilters: filters
    });
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(pathname, {
          onAddEditFilters: filters
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleSubmit = ({ image, ...values }) => {
    const slug = values.slug.startsWith('/')
      ? values.slug.substring(1)
      : values.slug;

    addUpdateTopic({
      variables: {
        data: {
          ...values,
          slug,
          imageId: image?.id || null
        },
        ...(isEdit && {
          id: topicId
        })
      }
    })
      .then(() => {
        history.push(ROUTES?.LABELS_TOPICS, {
          onAddEditFilters: filters
        });
      })
      .catch();
  };

  const handleNameChange = (e) => {
    form?.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader menu={addEditTopicBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            disabled={isViewOnly || fetchingDetails}
          >
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[
                formValidatorRules?.required('Please enter name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input placeholder="Enter name" onChange={handleNameChange} />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>
            <Form.Item label="Background Image" name="image">
              <SelectAsset
                disabled={isViewOnly}
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item label="Background Color" name="primaryColor">
              <CustomColorPicker />
            </Form.Item>
            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>
            <SeoInput form={form} />
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    loading={fetchingDetails || loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={fetchingDetails || loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditTopic;
