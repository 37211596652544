import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { CurrencyDollar } from 'phosphor-react';
import React, { useEffect } from 'react';
import {
  MAX_LENGTHS,
  MODULES,
  PLAN_FREQUENCY_OPTIONS,
  PLAN_TYPE_OPTIONS,
  REGEX,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import {
  formValidatorRules,
  normalizeNegativeNumber
} from '../../common/utils';
import HtmlEditor from '../../components/HtmlEditor';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import { CREATE_PLAN, UPDATE_PLAN } from './graphql/Mutations';
import { GET_SUBSCRIPTION_PLAN } from './graphql/Queries';

const AddEditPlans = ({ history, location, match: { params } }) => {
  const { planId } = params;
  const isEdit = !!planId;
  const [form] = Form.useForm();
  const { filters, pathname } = location?.state || {};

  const addEditSubscriptionPlansBreadcrumbs = [
    {
      label: MODULES?.SUBSCRIPTIONS,
      route: ROUTES?.SUBSCRIPTIONS,
      addEditFilter: { filters }
    },
    planId && { label: planId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [getSubscriptionPlan, { loading: fetchingDetails }] = useLazyQuery(
    GET_SUBSCRIPTION_PLAN,
    {
      onCompleted(res) {
        if (res?.subscriptionPlan) {
          form?.setFieldsValue({
            ...res?.subscriptionPlan,
            amount: res?.subscriptionPlan?.amount?.usd?.price
          });
        }
      },
      fetchPolicy: 'network-only',
      onError() {}
    }
  );

  const [
    createUpdateSubscriptionPlan,
    { loading: createLoading }
  ] = useMutation(isEdit ? UPDATE_PLAN : CREATE_PLAN, {
    onError() {}
  });

  useEffect(() => {
    if (planId) {
      getSubscriptionPlan({
        variables: {
          where: {
            id: planId
          }
        }
      });
    }
  }, [planId]);
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const normalizeAmount = (value) => {
    if (!value) {
      return null;
    }
    let newValue = value;
    newValue =
      newValue.indexOf('.') >= 0
        ? newValue.substr(0, newValue.indexOf('.')) +
          newValue.substr(newValue.indexOf('.'), 3)
        : newValue;

    return +newValue < 0 ? 0 : newValue;
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(pathname, {
          onAddEditFilters: filters
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      order: Number(values?.order),
      applyPermissions: false,
      amount: {
        usd: {
          price: Number(values?.amount)
        }
      },
      weight: Number(values?.weight)
    };
    if (isEdit) {
      delete payload.key;
      delete payload.frequency;
      delete payload.type;
      delete payload.applyPermissions;
    }

    const res = await createUpdateSubscriptionPlan({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: planId
          }
        })
      }
    });
    if (res?.data) {
      history?.push(ROUTES?.SUBSCRIPTIONS, { onAddEditFilters: filters });
    }
  };

  const handleCancel = () => {
    history.push(ROUTES?.SUBSCRIPTIONS, { onAddEditFilters: filters });
  };

  return (
    <>
      <PageHeader menu={addEditSubscriptionPlansBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form plan-form"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              isActive: false,
              showOnPaywall: false,
              applyPermissions: false,
              platformIds: {
                inAppIos: '',
                inAppAndroid: '',
                stripe: ''
              }
            }}
            disabled={isViewOnly || fetchingDetails}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Key"
                  name="key"
                  required
                  rules={[
                    {
                      validator(rule, value) {
                        if (!value) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject('Please enter a key!');
                        }
                        if (!REGEX?.KEY_VALUE?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject('Should be a valid key!');
                        }
                        return Promise?.resolve();
                      }
                    },
                    // need to set 100 as per requirement
                    formValidatorRules?.maxLength(100)
                  ]}
                >
                  <Input placeholder="Enter key" disabled={isEdit} />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter name!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
                  ]}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  name="amount"
                  normalize={normalizeAmount}
                  label="Amount"
                  rules={[{ required: true, message: 'Please enter amount!' }]}
                >
                  <Input
                    prefix={<CurrencyDollar size={20} />}
                    type="number"
                    placeholder="Enter amount"
                    className="amount-input"
                  />
                </Form.Item>
                <Form.Item
                  name="order"
                  label="Order"
                  normalize={normalizeNegativeNumber}
                >
                  <Input
                    type="number"
                    placeholder="Enter order"
                    className="number-input"
                  />
                </Form.Item>
                <Form.Item
                  name="weight"
                  label="Weight"
                  normalize={normalizeNegativeNumber}
                >
                  <Input
                    type="number"
                    placeholder="Enter weight"
                    className="number-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Frequency"
                  name="frequency"
                  rules={[
                    !isEdit && {
                      required: true,
                      message: 'Please select frequency!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Select frequency"
                    disabled={isEdit}
                    options={PLAN_FREQUENCY_OPTIONS}
                  />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    !isEdit && {
                      required: true,
                      message: 'Please select type!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Select type"
                    options={PLAN_TYPE_OPTIONS}
                    disabled={isEdit}
                  />
                </Form.Item>
                <Form.Item label="Stripe ID" name={['platformIds', 'stripe']}>
                  <Input placeholder="Enter stripe id" />
                </Form.Item>
                <Form.Item
                  label="In App Android ID"
                  name={['platformIds', 'inAppAndroid']}
                >
                  <Input placeholder="Enter in app android id" />
                </Form.Item>
                <Form.Item
                  label="In App iOS ID"
                  name={['platformIds', 'inAppIos']}
                >
                  <Input placeholder="Enter in app ios id" />
                </Form.Item>
                <Form.Item
                  className="m-0"
                  name="isActive"
                  valuePropName="checked"
                >
                  <Switch label="Active" />
                </Form.Item>
                <Form.Item
                  className="m-0"
                  name="showOnPaywall"
                  valuePropName="checked"
                >
                  <Switch label="Show on Paywall" />
                </Form.Item>
              </Col>
              <Col md={24} lg={12}>
                <Form.Item className="full-html" label="Html" name="html">
                  <HtmlEditor disabled={isViewOnly} className="full-html" />
                </Form.Item>
              </Col>
            </Row>

            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={createLoading}
                    loading={createLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={createLoading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditPlans;
