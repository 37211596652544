import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { cloneDeep, map, omit, reduce } from 'lodash';
import { CalendarBlank } from 'phosphor-react';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  AD_DEVICE_TYPE,
  AD_LAYOUT_TYPE,
  AD_LAYOUT_TYPE_OPTIONS,
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  REGEX,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION,
  defaultDateFormat
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import { GET_ADVERTISERS } from '../advertisers/graphql/Queries';
import SelectAsset from '../assets/components/SelectAsset';
import { SlugInput } from '../labels/topics/components/FormInputs';
import { Permissions } from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select, SelectContributor } from '../videos/components/FormInputs';
import { CREATE_AD, UPDATE_AD } from './graphql/Mutations';
import { GET_AD } from './graphql/Queries';

const initialValues = {
  title: '',
  description: '',
  slug: '/',
  contributors: [],
  config: {
    web: {
      layout: AD_LAYOUT_TYPE.BANNER
    },
    mobile: {
      layout: AD_LAYOUT_TYPE.BANNER
    }
  }
};

const AddEditAds = ({ history, location, match: { params } }) => {
  const [form] = Form?.useForm();
  const { filters, pathname } = location?.state || {};
  const { adId } = params;
  const isEdit = !!adId;
  const startDate = Form.useWatch(['startDate'], form);

  const addEditAdsBreadcrumbs = [
    { label: MODULES?.CONTENTS },
    {
      label: MODULES?.ADS,
      route: ROUTES?.CONTENTS_ADS,
      addEditFilter: { filters }
    },
    adId && { label: adId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const [addUpdateAd, { loading }] = useMutation(
    isEdit ? UPDATE_AD : CREATE_AD
  );

  const [fetchAdDetails, { loading: fetchingDetails }] = useLazyQuery(GET_AD, {
    fetchPolicy: 'network-only'
  });

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  useEffect(() => {
    if (isEdit && !!adId) {
      fetchAdDetails({
        variables: {
          where: {
            id: adId
          }
        }
      }).then((res) => {
        const ad = res.data?.adAdmin;
        if (ad) {
          const slug = ad?.slug.startsWith('/') ? ad?.slug : `/${ad?.slug}`;
          const updatedConfig = cloneDeep(ad?.config);
          map(Object.keys(omit(updatedConfig, '__typename')), (key) => {
            updatedConfig[key].images = {
              type: reduce(
                updatedConfig[key].images,
                (acc, image) => {
                  acc[image.type] = image.asset;
                  return acc;
                },
                {}
              )
            };
          });

          form.setFieldsValue({
            title: ad?.title ?? '',
            description: ad?.description ?? '',
            slug,
            permissions:
              ad?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            advertisers:
              ad?.advertisers?.map((advertiser) => ({
                label: advertiser?.name,
                value: advertiser?.id
              })) ?? [],
            contributors:
              ad?.contributors?.map((contributor) => ({
                id: contributor?.id ?? '',
                firstName: contributor?.firstName ?? '',
                lastName: contributor?.lastName ?? '',
                imageURL: contributor?.image?.url ?? '',
                color: contributor?.primaryColor ?? ''
              })) ?? [],
            config: updatedConfig,
            url: ad?.url ?? '',
            startDate: ad?.startDate ? dayjs(ad?.startDate) : null,
            endDate: ad?.endDate ? dayjs(ad?.endDate) : null
          });
        }
      });
    }
  }, [isEdit, adId, form, fetchAdDetails]);

  const handleCancel = () => {
    history.replace(ROUTES?.CONTENTS_ADS, {
      onAddEditFilters: filters
    });
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(pathname, {
          onAddEditFilters: filters
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleSubmit = (data) => {
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      contributors:
        data?.contributors?.map((contributor, index) => {
          return {
            contributorId: contributor?.id,
            order: index + 1
          };
        }) ?? [],
      advertisers:
        data?.advertisers?.map((advertiser, index) => {
          return {
            advertiserId: advertiser?.value,
            order: index + 1
          };
        }) || [],
      config: data?.config || {},
      permissions: data?.permissions?.map(({ value }) => value),
      url: data?.url || '',
      startDate: data?.startDate ? dayjs(data?.startDate).startOf('day') : null,
      endDate: data?.endDate ? dayjs(data?.endDate).endOf('day') : null
    };

    map(Object.keys(data?.config), (key) => {
      payload.config[key].images = map(
        data?.config[key].images.type,
        (imageItem, imageItemKey) => {
          return {
            type: imageItemKey,
            assetId: imageItem?.id
          };
        }
      );
    });

    addUpdateAd({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: adId
          }
        })
      }
    }).then(() => {
      history.push(ROUTES?.CONTENTS_ADS, {
        onAddEditFilters: filters
      });
    });
  };

  return (
    <>
      <PageHeader menu={addEditAdsBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter title!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <Input
                    placeholder="Enter title"
                    onChange={handleTitleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <SlugInput />
                </Form.Item>
                <Form.Item
                  name="advertisers"
                  label="Advertisers"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (value?.length < 1) {
                          throw new Error(
                            'Please select at least one advertiser!'
                          );
                        }
                      }
                    }
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select advertisers"
                    query={GET_ADVERTISERS}
                    variablesSelector={(filter) => ({
                      ...filter,
                      filter: { ...filter, isActive: true }
                    })}
                    dataSelector={(data) =>
                      data?.advertisersAdmin?.advertisers?.map(
                        ({ id, name }) => ({
                          label: name,
                          value: id
                        })
                      ) ?? []
                    }
                    keys={{
                      data: 'advertisersAdmin',
                      records: 'advertisers',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item label="Contributors" name="contributors">
                  <SelectContributor
                    displayText="Contributor"
                    disabled={isViewOnly}
                    multiple
                  />
                </Form.Item>
                <fieldset className="mb-12">
                  <legend className="role-legend">WEB LAYOUT</legend>
                  <Form.Item
                    name={['config', 'web', 'layout']}
                    required
                    rules={[
                      {
                        async validator(_, value) {
                          if (!value) {
                            throw new Error('Please select layout!');
                          }
                        }
                      }
                    ]}
                  >
                    <Radio.Group size="middle">
                      {AD_LAYOUT_TYPE_OPTIONS.map(({ value, label }) => (
                        <Radio value={value} key={value}>
                          {label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="Desktop Image"
                    name={[
                      'config',
                      'web',
                      'images',
                      'type',
                      AD_DEVICE_TYPE.DESKTOP
                    ]}
                    required
                    rules={[
                      {
                        async validator(_, value) {
                          if (!value || value?.id === '') {
                            throw new Error('Please select image!');
                          }
                        }
                      }
                    ]}
                  >
                    <SelectAsset
                      disabled={isViewOnly}
                      modalTitle="Select Image"
                      categoryKey={ASSET_CATEGORY.IMAGE}
                      btnText="Image"
                      dataSelector={({ id, url }) => ({
                        id,
                        url
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Tablet Image"
                    name={[
                      'config',
                      'web',
                      'images',
                      'type',
                      AD_DEVICE_TYPE.TABLET
                    ]}
                    required
                    rules={[
                      {
                        async validator(_, value) {
                          if (!value || value?.id === '') {
                            throw new Error('Please select image!');
                          }
                        }
                      }
                    ]}
                  >
                    <SelectAsset
                      disabled={isViewOnly}
                      modalTitle="Select Image"
                      categoryKey={ASSET_CATEGORY.IMAGE}
                      btnText="Image"
                      dataSelector={({ id, url }) => ({
                        id,
                        url
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Image"
                    name={[
                      'config',
                      'web',
                      'images',
                      'type',
                      AD_DEVICE_TYPE.MOBILE
                    ]}
                    required
                    rules={[
                      {
                        async validator(_, value) {
                          if (!value || value?.id === '') {
                            throw new Error('Please select image!');
                          }
                        }
                      }
                    ]}
                  >
                    <SelectAsset
                      disabled={isViewOnly}
                      modalTitle="Select Image"
                      categoryKey={ASSET_CATEGORY.IMAGE}
                      btnText="Image"
                      dataSelector={({ id, url }) => ({
                        id,
                        url
                      })}
                    />
                  </Form.Item>
                </fieldset>
                <fieldset className="mb-12">
                  <legend className="role-legend">MOBILE LAYOUT</legend>
                  <Form.Item
                    name={['config', 'mobile', 'layout']}
                    required
                    rules={[
                      {
                        async validator(_, value) {
                          if (!value) {
                            throw new Error('Please select layout!');
                          }
                        }
                      }
                    ]}
                  >
                    <Radio.Group size="middle">
                      {AD_LAYOUT_TYPE_OPTIONS.filter(
                        ({ value }) => value !== AD_LAYOUT_TYPE.RECTANGLE
                      ).map(({ value, label }) => (
                        <Radio value={value} key={value}>
                          {label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="Mobile Image"
                    name={[
                      'config',
                      'mobile',
                      'images',
                      'type',
                      AD_DEVICE_TYPE.MOBILE
                    ]}
                    required
                    rules={[
                      {
                        async validator(_, value) {
                          if (!value || value?.id === '') {
                            throw new Error('Please select image!');
                          }
                        }
                      }
                    ]}
                  >
                    <SelectAsset
                      disabled={isViewOnly}
                      modalTitle="Select Image"
                      categoryKey={ASSET_CATEGORY.IMAGE}
                      btnText="Image"
                      dataSelector={({ id, url }) => ({
                        id,
                        url
                      })}
                    />
                  </Form.Item>
                </fieldset>
                <Form.Item
                  rules={[
                    {
                      async validator(_, value) {
                        if (value && !REGEX?.WEB_URL?.test(value)) {
                          throw new Error('Should be a valid URL');
                        }
                      }
                    }
                  ]}
                  name="url"
                  label="Url"
                >
                  <Input placeholder="Enter url" />
                </Form.Item>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value) {
                          throw new Error('Please select start date!');
                        }
                      }
                    }
                  ]}
                >
                  <DatePicker
                    suffixIcon={<CalendarBlank size={20} />}
                    disabledDate={(current) =>
                      current.isBefore(dayjs().subtract(1, 'day'))
                    }
                    format={defaultDateFormat}
                  />
                </Form.Item>
                <Form.Item name="endDate" label="End Date">
                  <DatePicker
                    disabled={!startDate}
                    suffixIcon={<CalendarBlank size={20} />}
                    disabledDate={(current) =>
                      current.isBefore(dayjs(startDate))
                    }
                    format={defaultDateFormat}
                  />
                </Form.Item>
                <Permissions />
              </Col>
            </Row>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditAds;
