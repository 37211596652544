import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import {
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from '../videos/components/FormInputs';
import {
  CREATE_WORKSPACE_USER,
  UPDATE_WORKSPACE_USER
} from './graphql/Mutations';
import { GET_WORKSPACE_ROLES, GET_WORKSPACE_USER } from './graphql/Queries';

const initialValues = {
  isActive: true,
  roleKeys: null,
  user: {
    firstName: '',
    lastName: '',
    email: ''
  }
};

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const AddEditWorkspaceUsers = ({ history, location, match: { params } }) => {
  const [form] = Form?.useForm();
  const { filters, pathname } = location?.state || {};
  const { userId } = params;
  const isEdit = !!userId;

  const addEditMembersBreadcrumbs = [
    { label: MODULES?.WS_SETTINGS },
    {
      label: MODULES?.MEMBERS,
      route: ROUTES?.WS_MEMBERS,
      addEditFilter: { filters }
    },
    userId && { label: userId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [getWorkspaceUser, { loading: fetchingDetails }] = useLazyQuery(
    GET_WORKSPACE_USER,
    {
      fetchPolicy: 'network-only',
      onError: () => {}
    }
  );

  const [addUpdateWorkspaceUser, { loading }] = useMutation(
    isEdit ? UPDATE_WORKSPACE_USER : CREATE_WORKSPACE_USER
  );

  useEffect(() => {
    if (isEdit && !!userId) {
      getWorkspaceUser({
        variables: {
          where: {
            id: userId
          }
        }
      }).then(({ data, error }) => {
        if (!error && !!data) {
          const workspaceUser = data?.workspaceUser;
          const userObject = workspaceUser?.user;
          form?.setFieldsValue({
            isActive: workspaceUser?.isActive,
            roleKeys: {
              label: workspaceUser?.roles?.[0]?.name,
              value: workspaceUser?.roles?.[0]?.key
            },
            user: {
              firstName: userObject?.firstName,
              lastName: userObject?.lastName,
              email: userObject?.email
            }
          });
        }
      });
    }
  }, [isEdit, userId, form]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(pathname, {
          onAddEditFilters: filters
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      roleKeys: [values?.roleKeys?.value]
    };

    if (isEdit) {
      delete payload?.user;
    }

    addUpdateWorkspaceUser({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: userId
          }
        })
      }
    }).then(() => {
      history?.push(ROUTES?.WS_MEMBERS, {
        onAddEditFilters: filters
      });
    });
  };

  const handleCancel = () => {
    history?.push(ROUTES?.WS_MEMBERS, {
      onAddEditFilters: filters
    });
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.WORKSPACE_USER_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.WORKSPACE_USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader menu={addEditMembersBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
          >
            <Form.Item
              label="First Name"
              name={['user', 'firstName']}
              required
              rules={[
                formValidatorRules?.required('Please enter first name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter first name"
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name={['user', 'lastName']}
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.NAME)]}
            >
              <Input
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter last name"
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name={['user', 'email']}
              rules={[
                {
                  required: true,
                  message: 'Please enter email!'
                },
                formValidatorRules?.email,
                formValidatorRules?.maxLength(MAX_LENGTHS.FORM_INPUT)
              ]}
            >
              <Input
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter email"
              />
            </Form.Item>
            <Form.Item
              label="Roles"
              name="roleKeys"
              rules={[
                {
                  async validator(_, value) {
                    if (!value) {
                      throw new Error('Please select role!');
                    }
                  }
                }
              ]}
            >
              <Select
                popupMatchSelectWidth={false}
                variablesSelector={variablesSelector}
                placeholder="Select role"
                query={GET_WORKSPACE_ROLES}
                dataSelector={(data) =>
                  data?.workspaceRoles?.workspaceRoles?.map(
                    ({ key, name }) => ({
                      label: name,
                      value: key
                    })
                  ) || []
                }
                keys={{
                  data: 'workspaceRoles',
                  records: 'workspaceRoles',
                  count: 'count'
                }}
              />
            </Form.Item>
            <Form.Item className="m-0" name="isActive" valuePropName="checked">
              <Switch label="Active" />
            </Form.Item>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditWorkspaceUsers;
