import { CheckOutlined } from '@ant-design/icons';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  theme
} from 'antd';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import videoPlaceholder from '../assets/images/imagePlaceholder.png';
import useDebounce from '../hooks/useDebounce';
import { GET_PRODUCTS } from '../modules/pages/graphql/Queries';
import Preview from '../modules/videos/components/Preview';
import Image from './Image';
import InView from './InView';

const LIMIT = 12;

const ModalContent = ({ onSelect, value, search }) => {
  const [cursor, setCursor] = useState('');
  const debouncedSearch = useDebounce(search?.trim());
  const [getProducts, { fetchMore, data, networkStatus }] = useLazyQuery(
    GET_PRODUCTS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    (async () => {
      try {
        const { data: res, error } = await getProducts({
          variables: {
            filter: {
              limit: LIMIT,
              after: '',
              search: debouncedSearch
            }
          }
        });
        if (error) throw new Error(error);
        if (res) {
          const lastRecord = res?.products[res?.products?.length - 1];
          setCursor(lastRecord?.cursor ?? '');
        }
      } catch (error) {
        return error;
      }
    })();
  }, [getProducts, debouncedSearch]);

  const records = useMemo(() => (data ? data?.products : []), [data]);

  const hasMoreData = Boolean(cursor);

  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  const fetchMoreRecords = useCallback(() => {
    fetchMore({
      variables: {
        filter: {
          limit: LIMIT,
          after: cursor,
          search: debouncedSearch
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult) {
          const lastRecord =
            fetchMoreResult?.products[fetchMoreResult?.products?.length - 1];
          setCursor(lastRecord?.cursor ?? '');
          return {
            ...prev,
            products: [...prev?.products, ...fetchMoreResult?.products]
          };
        }
        return {
          ...prev
        };
      }
    });
  }, [fetchMore, cursor]);

  return (
    <div className="selectable-modal-body">
      {loading ? (
        <div className="selectable-modal-wrapper">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {!records.length ? (
            <div className="selectable-modal-wrapper">
              <Empty />
            </div>
          ) : (
            <>
              <Row gutter={[16, 16]}>
                {records.map((record) => {
                  const isSelected = Array.isArray(value)
                    ? value?.map((val) => val?.id).includes(record?.id)
                    : value?.id === record?.id;
                  return (
                    <Col xs={6} key={record?.id}>
                      <Card
                        className={`selectable-modal-card cs-card ${
                          isSelected ? 'selected-item' : ''
                        }`}
                        cover={
                          <>
                            {isSelected && (
                              <span className="checked-icon">
                                <CheckOutlined />
                              </span>
                            )}
                            <Image
                              className="label-poster"
                              src={record?.images?.[0] ?? videoPlaceholder}
                              alt=""
                            />
                          </>
                        }
                        onClick={() => onSelect(record)}
                      >
                        <Card.Meta
                          title={
                            <span className="label-title">{record?.title}</span>
                          }
                        />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              {isFetchingMore && (
                <div className="d-flex justify-center pt-16">
                  <Spin />
                </div>
              )}
              {!isFetchingMore && hasMoreData && !loading && (
                <InView
                  onChange={({ inView }) => {
                    if (inView) {
                      fetchMoreRecords();
                    }
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const ProductsSelectModal = ({
  open,
  onClose,
  isAssetEditAllowed,
  categoryKey,
  ...rest
}) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!open) setSearch('');
  }, [open]);

  const handleSearchChange = (e) => {
    setSearch(e?.target?.value);
  };

  return (
    <Modal
      open={open}
      title="Select Product"
      onCancel={onClose}
      width="90%"
      className="selectable-modal"
      footer={null}
      destroyOnClose
    >
      <Row className="mb-12">
        <Col xs={8}>
          <Input
            placeholder="Search Product"
            allowClear
            className="search-component"
            name="search"
            value={search}
            onChange={handleSearchChange}
            prefix={<MagnifyingGlass size={20} />}
          />
        </Col>
      </Row>
      {open && <ModalContent search={search} {...rest} />}
    </Modal>
  );
};

export default ProductsSelectModal;

export const SelectProducts = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.images?.[0] ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.images?.[0] ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <ProductsSelectModal
        open={isOpen}
        onClose={closeModal}
        onSelect={handleCardSelect}
        value={value}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={item?.url || videoPlaceholder}
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={value?.url || videoPlaceholder}
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Products' : 'Product'}
        </Button>
      </Space>
    </>
  );
};
