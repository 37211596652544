import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect } from 'react';
import {
  AUTO_GENERATED_SORT_BY_OPTIONS,
  AUTO_GENERATED_SORT_BY_TYPES,
  CONFIG_METHOD_OPTIONS,
  CONFIG_METHOD_TYPES,
  MODULE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { SelectProducts } from '../../../../../components/ProductsSelectModal';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  config: {
    method: null,
    autoGenerateSortBy: null,
    productId: null,
    ctaTitle: null
  }
};

const MODULE_KEYS = {
  [MODULE_TYPES.FEATURED_PRODUCT]: 'featuredProductModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.FEATURED_PRODUCT]: 'Featured Product Configs'
};

const StoreForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const configProps = Form.useWatch(['config'], form);
  const { method } = configProps ?? initialValues.config;

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        config: {
          method: defaultValues?.moduleData?.config?.method ?? null,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.productSortBy || null,
          ctaTitle: defaultValues?.moduleData?.config?.ctaTitle ?? null,
          productId: defaultValues?.moduleData?.config?.product
            ? {
                ...defaultValues?.moduleData?.config?.product,
                url: defaultValues?.moduleData?.config?.product?.images?.[0]
              }
            : null
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const { method: storeMethod, productId, ...restConfig } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig,
          method: storeMethod ?? CONFIG_METHOD_TYPES.MANUAL,
          productId: productId?.id
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.Item label="CTA Title" name={['config', 'ctaTitle']}>
              <Input placeholder="Enter CTA title" />
            </Form.Item>
            <Form.Item label="Config Method" name={['config', 'method']}>
              <AntdSelect
                options={CONFIG_METHOD_OPTIONS}
                placeholder="Select method"
                onChange={() => {
                  form.resetFields([['config', 'productId']]);
                }}
              />
            </Form.Item>
            {method === CONFIG_METHOD_TYPES.AUTO_GENERATED && (
              <>
                <Form.Item
                  label="Auto Generated Sort By"
                  name={['config', 'autoGenerateSortBy']}
                >
                  <AntdSelect
                    options={AUTO_GENERATED_SORT_BY_OPTIONS?.filter(
                      ({ value }) =>
                        value === AUTO_GENERATED_SORT_BY_TYPES.RECENT
                    )}
                    placeholder="Select sort by"
                  />
                </Form.Item>
              </>
            )}
            {method === CONFIG_METHOD_TYPES.MANUAL && (
              <Form.Item label="Select Product" name={['config', 'productId']}>
                <SelectProducts multiple={false} />
              </Form.Item>
            )}
          </div>
        </Space>
      </Space>
      <Permissions />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="text"
              className="text-btn2"
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default StoreForm;
