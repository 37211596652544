import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_SORT_BY_TYPES,
  CONFIG_METHOD_OPTIONS,
  CONFIG_METHOD_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectContributor } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  permissions: [],
  settings: {
    title: true,
    description: true,
    viewAll: true,
    numberOfVideos: true,
    separator: true,
    contributorDescription: true,
    contributorName: true,
    contributorImage: true,
    numberOfPodcasts: true,
    numberOfArticles: true
  },
  config: {
    autoGenerateSortBy: null,
    autoGenerateLimit: null,
    contributors:
      type === MODULE_TYPES.FEATURED_CONTRIBUTOR
        ? {
            id: '',
            firstName: '',
            lastName: '',
            imageURL: '',
            color: ''
          }
        : [],
    method: null
  }
});

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.CONTRIBUTOR_CAROUSEL,
      MODULE_TYPES.CONTRIBUTOR_GRID,
      MODULE_TYPES.CONTRIBUTOR_LIST
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.CONTRIBUTOR_LIST,
      MODULE_TYPES.CONTRIBUTOR_CAROUSEL,
      MODULE_TYPES.CONTRIBUTOR_GRID
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [MODULE_TYPES.CONTRIBUTOR_CAROUSEL]
  },
  {
    name: 'contributorName',
    label: 'Contributor Name',
    allowedTypes: [MODULE_TYPES.FEATURED_CONTRIBUTOR]
  },
  {
    name: 'contributorDescription',
    label: 'Contributor Description',
    allowedTypes: [MODULE_TYPES.CONTRIBUTOR_LIST]
  },
  {
    name: 'contributorImage',
    label: 'Contributor Image',
    allowedTypes: [MODULE_TYPES.FEATURED_CONTRIBUTOR]
  },
  {
    name: 'numberOfVideos',
    label: 'Number Of Video',
    allowedTypes: [
      MODULE_TYPES.FEATURED_CONTRIBUTOR,
      MODULE_TYPES.CONTRIBUTOR_LIST,
      MODULE_TYPES.CONTRIBUTOR_CAROUSEL,
      MODULE_TYPES.CONTRIBUTOR_GRID
    ]
  },
  {
    name: 'numberOfPodcasts',
    label: 'Number Of Podcast',
    allowedTypes: [
      MODULE_TYPES.FEATURED_CONTRIBUTOR,
      MODULE_TYPES.CONTRIBUTOR_LIST,
      MODULE_TYPES.CONTRIBUTOR_CAROUSEL,
      MODULE_TYPES.CONTRIBUTOR_GRID
    ]
  },
  {
    name: 'numberOfArticles',
    label: 'Number Of Articles',
    allowedTypes: [
      MODULE_TYPES.CONTRIBUTOR_CAROUSEL,
      MODULE_TYPES.CONTRIBUTOR_GRID,
      MODULE_TYPES.CONTRIBUTOR_LIST,
      MODULE_TYPES.FEATURED_CONTRIBUTOR
    ]
  },
  {
    name: 'separator',
    label: 'Separator',
    allowedTypes: [MODULE_TYPES.CONTRIBUTOR_LIST]
  }
];

const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM }
];

const MODULE_KEYS = {
  [MODULE_TYPES.CONTRIBUTOR_CAROUSEL]: 'contributorCarouselModule',
  [MODULE_TYPES.CONTRIBUTOR_LIST]: 'contributorListModule',
  [MODULE_TYPES.CONTRIBUTOR_GRID]: 'contributorGridModule',
  [MODULE_TYPES.FEATURED_CONTRIBUTOR]: 'featuredContributorModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.CONTRIBUTOR_CAROUSEL]: 'Contributor Carousel Configs',
  [MODULE_TYPES.CONTRIBUTOR_LIST]: 'Contributor List Configs',
  [MODULE_TYPES.CONTRIBUTOR_GRID]: 'Contributor Grid Configs',
  [MODULE_TYPES.FEATURED_CONTRIBUTOR]: 'Feature Contributor Configs'
};
const { CONTRIBUTOR } = PAGE_TYPES;
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.FEATURED_CONTRIBUTOR]: [CONTRIBUTOR]
};

const ContributorForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);

  const isMultiContributor = type !== MODULE_TYPES.FEATURED_CONTRIBUTOR;
  const configProps = Form.useWatch(['config'], form);
  const { method } = configProps ?? initialValues.config;
  const settingsProps = Form.useWatch(['settings'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (settingsProps) {
      onSettingsChange?.(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          method: defaultValues?.moduleData?.config?.method ?? null,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.contributorAutoGenerateSortBy ||
            null,
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          contributors: isMultiContributor
            ? defaultValues?.moduleData?.config?.contributors?.map(
                ({ id, firstName, lastName, image, primaryColor }) => ({
                  id,
                  firstName,
                  lastName,
                  color: primaryColor,
                  imageURL: image?.url ?? ''
                })
              ) || []
            : {
                id: defaultValues?.moduleData?.config?.contributor?.id ?? '',
                firstName:
                  defaultValues?.moduleData?.config?.contributor?.firstName ??
                  '',
                lastName:
                  defaultValues?.moduleData?.config?.contributor?.lastName ??
                  '',
                color:
                  defaultValues?.moduleData?.config?.contributor
                    ?.primaryColor ?? '',
                imageURL:
                  defaultValues?.moduleData?.config?.contributor?.image?.url ??
                  ''
              }
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiContributor
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const isDefault = allowedTemplateConfig && isDefaultModule;

    // eslint-disable-next-line no-shadow
    const {
      contributors,
      method: configMethod,
      autoGenerateLimit,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          method: configMethod ?? CONFIG_METHOD_TYPES.MANUAL,
          ...restConfig,
          ...(isDefault
            ? {
                contributorId: '*'
              }
            : {
                ...(configMethod === CONFIG_METHOD_TYPES.MANUAL &&
                  isMultiContributor && {
                    contributors:
                      contributors.map(({ id }, i) => ({
                        contributorId: id,
                        order: i + 1
                      })) ?? []
                  }),
                ...(configMethod === CONFIG_METHOD_TYPES.MANUAL &&
                  !isMultiContributor && {
                    contributorId: contributors?.id ?? ''
                  })
              }),
          ...(isMultiContributor && {
            autoGenerateLimit: autoGenerateLimit
              ? Number(autoGenerateLimit)
              : null
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === CONTRIBUTOR && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                <Form.Item label="Config Method" name={['config', 'method']}>
                  <AntdSelect
                    options={CONFIG_METHOD_OPTIONS?.filter(
                      ({ value }) => value !== CONFIG_METHOD_TYPES.RECOMMENDED
                    )}
                    placeholder="Select method"
                    onChange={() => {
                      form.resetFields([['config', 'contributors']]);
                    }}
                  />
                </Form.Item>
                {method === CONFIG_METHOD_TYPES.AUTO_GENERATED && (
                  <>
                    <Form.Item
                      label="Auto Generated Sort By"
                      name={['config', 'autoGenerateSortBy']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_SORT_BY_OPTIONS}
                        placeholder="Select sort by"
                      />
                    </Form.Item>
                  </>
                )}
                {[
                  CONFIG_METHOD_TYPES.AUTO_GENERATED,
                  CONFIG_METHOD_TYPES.RECOMMENDED
                ]?.includes(method) && (
                  <>
                    {isMultiContributor && (
                      <Form.Item
                        label="No. of Auto generated Contributors"
                        name={['config', 'autoGenerateLimit']}
                        rules={[
                          formValidatorRules?.number,
                          formValidatorRules?.maxNumberAllowed(
                            configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                          )
                        ]}
                      >
                        <Input placeholder="Enter number" />
                      </Form.Item>
                    )}
                  </>
                )}
                {method === CONFIG_METHOD_TYPES.MANUAL && (
                  <Form.Item
                    label={
                      isMultiContributor
                        ? 'Select Contributors'
                        : 'Select Contributor'
                    }
                    name={['config', 'contributors']}
                    extra={
                      isMultiContributor ? 'Select items in order you want' : ''
                    }
                  >
                    <SelectContributor multiple={isMultiContributor} />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default ContributorForm;
