import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { flattenDeep, groupBy, map, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MODULES, ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import PageHeader from '../../components/PageHeader';
import LayoutRow from '../component/layout/LayoutRow';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import LeftPanelPermissions from './components/LeftPanelPermissions';
import MiddlePanelPermissions from './components/MiddlePanelPermissions';
import RightPanelPermissions from './components/RightPanelPermissions';
import { UPDATE_PLAN_PERMISSIONS } from './graphql/Mutations';
import { SUBSCRIPTION_PERMISSIONS } from './graphql/Queries';

const EditPermissions = ({ match: { params }, location, history }) => {
  const { planId } = params;
  const [form] = Form.useForm();
  const { filters, pathname } = location?.state || {};
  const [permissions, setPermissions] = useState([]);
  const [moduleKey, setModuleKey] = useState('');
  const [permissionKey, setPermissionKey] = useState('');
  const [allPermissions, setAllPermissions] = useState([]);
  const [permissionsValues, setPermissionsValues] = useState({});
  const [allowedPermission, setAllowedPermission] = useState(false);
  const [permissionsLoading, setPermissionLoading] = useState(false);

  const [fetchSubscriptionPermissions, { data, loading }] = useLazyQuery(
    SUBSCRIPTION_PERMISSIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const groupPermissions = groupBy(
          res?.subscriptionPlanPermissions,
          'group'
        );
        const updatedPermissions = Object.values(groupPermissions);
        setPermissions(uniqBy(flattenDeep(updatedPermissions), 'group'));
        setModuleKey(
          uniqBy(flattenDeep(updatedPermissions), 'group')?.[0]?.group
        );
        let updateValues = {};
        map(res?.subscriptionPlanPermissions, (item) => {
          updateValues = {
            ...updateValues,
            [item?.key]: {
              allowed: true,
              applyLimit: false,
              limit: 0,
              frequency: 0,
              errorMessage: null,
              limitMessage: null,
              permissionType: item?.type,
              errorTitle: null,
              limitTitle: null
            }
          };
        });
        setPermissionsValues(updateValues);
      },
      onError() {}
    }
  );

  const [
    updatePlanPermissions,
    { loading: submitPermissionLoading }
  ] = useMutation(UPDATE_PLAN_PERMISSIONS, {
    onError() {}
  });

  const permissionData = groupBy(data?.subscriptionPlanPermissions, 'group')?.[
    moduleKey
  ];

  const permissionBreadcrumbs = [
    {
      label: MODULES?.SUBSCRIPTIONS,
      route: ROUTES?.SUBSCRIPTIONS,
      addEditFilter: { filters }
    },
    planId && { label: planId },
    { label: MODULES?.PERMISSIONS }
  ].filter(Boolean);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(pathname, {
          onAddEditFilters: filters
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const onChange = (checked) => {
    updatePlanPermissions({
      variables: {
        data: {
          applyPermissions: checked,
          permissions: allPermissions
        },
        where: {
          id: planId
        }
      },
      onCompleted: () => {
        setAllowedPermission(checked);
      }
    });
  };

  useEffect(() => {
    fetchSubscriptionPermissions();
  }, []);

  useEffect(() => {
    if (moduleKey) {
      setPermissionKey(permissionData?.[0]?.key);
    }
  }, [moduleKey]);

  return (
    <>
      <PageHeader menu={permissionBreadcrumbs}>
        <Switch
          onChange={onChange}
          label="Apply Permissions"
          reverse
          checked={allowedPermission}
          disabled={submitPermissionLoading || permissionsLoading || loading}
        />
      </PageHeader>
      <div className="page-wrapper">
        <div className="page-wrapper-body edit-page">
          {loading ? (
            <LoaderComponent />
          ) : (
            <LayoutRow>
              <LeftPanelPermissions
                moduleKey={moduleKey}
                setModuleKey={setModuleKey}
                form={form}
                permissions={permissions}
                loading={loading}
                permissionsLoading={permissionsLoading}
              />

              <MiddlePanelPermissions
                permissionKey={permissionKey}
                setPermissionKey={setPermissionKey}
                form={form}
                permissions={permissionData}
                loading={loading}
                permissionsLoading={permissionsLoading}
              />

              <RightPanelPermissions
                form={form}
                loading={loading}
                permissionKey={permissionKey}
                planId={planId}
                allPermissions={allPermissions}
                setAllPermissions={setAllPermissions}
                allowedPermission={allowedPermission}
                permissionsValues={permissionsValues}
                setAllowedPermission={setAllowedPermission}
                permissionsLoading={permissionsLoading}
                setPermissionLoading={setPermissionLoading}
                permissionData={data?.subscriptionPlanPermissions}
              />
            </LayoutRow>
          )}
        </div>
      </div>
    </>
  );
};

export default EditPermissions;
