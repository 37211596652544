import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import history from '../../historyData';
import PreviewBar from '../component/Previewbar';
import LayoutRow from '../component/layout/LayoutRow';
import LeftPanel from './component/pageModules/LeftPanel';
import PreviewWrapper from './component/pageModules/Preview';
import RightPanel from './component/pageModules/RightPanel';
import EditPageProvider from './context/EditPageProvider';
import PreviewPageProvider from './context/PreviewPageProvider';

const EditPage = () => {
  const params = useParams();
  const location = useLocation();
  const { filters, pathname } = location?.state || {};

  const editPagesBreadcrumbs = [
    { label: MODULES?.UI_CONFIGURATIONS },
    {
      label: MODULES?.PAGES,
      route: ROUTES?.UI_CONFIG_PAGES,
      addEditFilter: { filters }
    },
    params?.id && { label: params?.id },
    { label: 'Edit' }
  ];

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(pathname, {
          onAddEditFilters: filters
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <PageHeader menu={editPagesBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body edit-page">
          <LayoutRow>
            <LeftPanel />
            <RightPanel />
            <PreviewBar>
              <PreviewWrapper />
            </PreviewBar>
          </LayoutRow>
        </div>
      </div>
    </>
  );
};

const wrapper = () => {
  return (
    <PreviewPageProvider>
      <EditPageProvider>
        <EditPage />
      </EditPageProvider>
    </PreviewPageProvider>
  );
};

export default wrapper;
