import { gql } from '@apollo/client';

export const GET_PAGES = gql`
  query pagesAdmin($search: String) {
    pagesAdmin(filter: { search: $search }) {
      count
      pages {
        id
        slug
        title
      }
    }
  }
`;

export const GET_MENUS = gql`
  fragment MenuItemFragment on Menu {
    id
    name
    icons {
      default {
        url
      }
    }
    type
    url
    position
    order
  }
  query menusAdmin($filter: MenusFilter) {
    menusAdmin(filter: $filter) {
      count
      menus {
        ...MenuItemFragment
      }
    }
  }
`;

export const GET_SLUGS = gql`
  query slugs($filter: SlugFilter!) {
    slugs(filters: $filter) {
      count
      slugs: slug {
        slug
      }
    }
  }
`;

export const GET_MENU = gql`
  query menuAdmin($id: ID!) {
    menuAdmin(where: { id: $id }) {
      name
      icons {
        default {
          id
          url
        }
        disabled {
          id
          url
        }
        focus {
          id
          url
        }
        hover {
          id
          url
        }
      }
      position
      showInSideMenu
      internalPageType
      type
      url
      altTag
      openInSameTab
      rel
      languageWiseMenu {
        language {
          code
          id
          name
        }
        name
      }
    }
  }
`;

export const GET_LANGUAGES = gql`
  query languages($filter: LanguageFilter) {
    languages(filter: $filter) {
      count
      languages {
        code
        id
        name
      }
    }
  }
`;
